.playerHudContainer{
    background-color: green;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 16px;
    .bobProfile{
        width: 300px;
    }
    .bobState{
        margin: 10px 0;
    }
}
.bobSelectorContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    .bob{
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .bob{
        img{
            max-height: 300px;
            max-width: 300px;
        }
    }
    
}
.battleContainer {
    display: flex;
    justify-content: space-between;
    .playerHud, .enemyHud {
        width: 25vw;
    }
    .battleStatContainer{
        width: 50vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .attackButton {
        width: 25%;
    }
}